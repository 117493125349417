/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'more': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 11.556a1.778 1.778 0 100 3.555 1.778 1.778 0 000-3.555zm0-5.334a1.778 1.778 0 100 3.556 1.778 1.778 0 000-3.556zM6.222 2.667a1.778 1.778 0 113.556 0 1.778 1.778 0 01-3.556 0z" _fill="#fff"/>'
  }
})
